import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Division, LangChooseFile, LangDownloadImportFormat, LangImportant, LangImportCancel, LangImportDone, LangImportErrorMsg1, LangImportErrorMsg2, LangImportErrorReason, LangImportFailedCount, LangImportMakeSure, LangImportSeparate, LangImportSuccessCount, LangMaxSize, LangOptional, LangProcess, LangResetDataFirst, LangRow } from '../../constant/languange'
import { axiosCompany, axiosCompanyFile, mapDispatchToProps, mapStateToProps } from '../../helpers/Common'
import { SpinnerButton } from '../../helpers/Spinner'
import ReactTooltip from 'react-tooltip'

function PersonnelImportComponent ({ redux, setState, ...props }) {
  const initData = { file: '', group_id: '' }
  // const excelUrl = 'https://kerjoo.com/wp-content/uploads/2023/04/KERJOO-Format-Import-Karyawan-2023-04-12.xlsx'

  const [errors, setError] = useState({ fields: [], message: '' })
  const [control, setControl] = useState({ showLoad: false })
  const [importResult, setImportResult] = useState(null)
  const [data, setData] = useState(initData)
  const [excelUrl, setExcelUrl] = useState('')

  useEffect(() => {
    downloadFormatExcel()
  }, [])
  

  const importExcelService = (e) => {
    e.preventDefault()
    if(redux.isTemporary) return
    const formData = new window.FormData()
    formData.append('file', data.file)
    formData.append('group_id', data.group_id)

    setControl({ ...control, showLoad: true })
    axiosCompany().post('/personnels/import-excel/', formData).then(ress => {
      setError({ fields: [], message: '' })
      setImportResult(ress.data)
      setControl({ ...control, showLoad: false })
      props.onUpdate()

      setState({ isUpdateStats: redux.isUpdateStats + 1 })
    }).catch(e => {
      console.log(e.response)
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const handleChangeFile = (e) => {
    if (e.target.files[0]) {
      setData({ ...data, file: e.target.files[0] })
    }
  }

  const downloadFormatExcel = () => {
    axiosCompanyFile().get('/personnels/format-import-excel').then(ress => {
      const href = URL.createObjectURL(ress.data)
      setExcelUrl(href)
    })
  }

  return (
    <div>
      <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'}`}>
        <b>{LangImportant.toUpperCase()}!</b>
        <ul style={{ listStyleType: 'circle', paddingLeft: '40px' }}>
          <li>{LangImportMakeSure}. <a download={'KERJOO-Format-Import-Karyawan'} href={excelUrl} target='_blank' rel='noopener noreferrer'>{LangDownloadImportFormat}</a>.</li>
          <li>{LangImportSeparate}.</li>
        </ul>
      </div>

      <form className='form' onSubmit={(e) => { importExcelService(e) }} autoComplete='off'>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{Division} ({LangOptional})</label>
          <div className='col-sm-5'>
            <select className='form-control' onChange={(e) => setData({ ...data, group_id: e.target.value })}>
              <option value=''>--{Division}--</option>
              {redux.groups.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{LangChooseFile} <b className='text-danger'>*</b></label>
          <div className='col-sm-4'>
            <input
              type='file'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={handleChangeFile}
            />
            <div className='help'>{LangMaxSize}: 500 KB</div>
            {(errors.fields.file) ? <div className='invalid-feedback d-block'><strong>{errors.fields.file}</strong></div> : null}
          </div>
        </div>
        <hr />
        <div className='form-group row'>
          <div className='col-sm-9 offset-md-3'>
            {errors.message ? <div className='alert alert-danger'>{errors.message}</div> : null}
            {importResult
              ? (
                <div>
                  <div className='alert alert-primary'>
                    {LangImportSuccessCount}: {importResult.success_count}
                    <br />{LangImportFailedCount}: {importResult.error_count}
                    {(+importResult.error_count > 0)
                      ? (
                        <div>
                          <br />
                          {LangImportErrorReason}: <br />
                          <ol style={{ display: 'inline-block', marginLeft: '-25px' }}>
                            {Object.keys(importResult.errors).map((key, index) => {
                              const err = importResult.errors[key]
                              return (
                                <li key={index}>{LangRow} {err.row + 1} = {err.msg}</li>
                              )
                            })}
                          </ol>
                          <br />
                          {LangImportErrorMsg1}
                          <br />
                          {LangImportErrorMsg2}
                        </div>
                        )
                      : null}
                  </div>
                  <button onClick={() => props.handleClose()} type='button' className='btn btn-light'>{LangImportDone}</button>
                </div>
                )
              : (
                <div>
                  {
                    redux.isTemporary ?
                      <>
                        <button disabled={true} data-for='tooltip-info-reset-data' data-tip={LangResetDataFirst} type='submit' className='btn btn-primary'>{(control.showLoad) ? SpinnerButton : null} {LangProcess}</button>
                        <ReactTooltip id='tooltip-info-reset-data' place='bottom' />
                      </>
                      :
                      <button disabled={control.showLoad} type='submit' className='btn btn-primary'>{(control.showLoad) ? SpinnerButton : null} {LangProcess}</button>
                  }
                  &nbsp;
                  <button disabled={control.showLoad} onClick={() => props.handleClose()} type='button' className='btn btn-light'>{LangImportCancel}</button>
                </div>
                )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelImportComponent)
