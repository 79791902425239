import React, { Fragment, useState, useEffect } from 'react'
import { MENUITEMS, MENUITEMSSUPERVISOR, NEWMENUITEMS, NEWMENUITEMSSUPERVISOR } from './menu'
import { ArrowRight, ArrowLeft, ChevronDown, ChevronRight } from 'react-feather'
import { Link, useLocation, matchPath } from 'react-router-dom'
import { translate } from 'react-switch-lang'
import configDB from '../../data/customizer/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight, faAngleLeft, faChevronDown, faRetweet } from '@fortawesome/free-solid-svg-icons'
import LogoDark from '../../assets/images/logo/dark.svg'
import LogoLight from '../../assets/images/logo/light.svg'
import LogoIcon from '../../assets/images/logo/logo-icon.png'
import { SpinnerButton, axiosCompany, connect, cookies, displayTimezone, handleDarkMode, mapDispatchToProps, mapStateToProps } from '../../helpers/Common'
import { AttendanceSpot, CompanySetting, DataEmployees, Employe, LangAdjustment, LangAnalytics, LangApproval, LangAttendance, LangAutoMode, LangClaim, LangDarkMode, LangEn, LangId, LangLightMode, LangOther, LangReview, LangSubscription, LangSwitchToNewVersion, LangSwitchToOldVersion, LangSystem, LangTimesheet, LangUnpaidLeave, LangUserAdmin, ManageDivision, ManageEmployees, ShiftDay, ShiftWorkTime, WorkDay, WorkTime } from '../../constant/languange'

const Sidebar = (props) => {
  const handleFeatures = (menu = []) => {
    // filter menu
    const features = []
    if (!props.redux.enableFeatureLimitAttendance) features.push('Attendance Approval')
    if (!props.redux.enableWhitelistDevice) features.push('Device Approval')
    if (!props.redux.enableFeaturePayroll) features.push('Payroll Calculate')
    if (!props.redux.enableFeatureClientVisit) features.push('Client Visit')
    if (!props.redux.enableFeatureReimbursement) features.push('Claim')
    if (!props.redux.isTemporary) features.push('Reset')
    if (!props.redux.enableFeatureTimesheet) features.push('Timesheet')
    // if (typeof props.redux.addons === 'object' && props.redux.addons !== null && 'pintu_kerjoo' in props.redux.addons) {
    if (!props.redux.enableFeaturePintuKerjoo) {
      features.push('Pintu Kerjoo')
    } else {
      features.push('Pintu Kerjoo Landing Page')
    }
    const filteredMenu = menu.slice().filter(item => !features.includes(item.menucontent))

    // filter submenu
    const filteredMenuAndSubMenu = filteredMenu.map(item => {
      if (item.Items[0].children) {
        const children = [...item.Items[0].children]

        const subFeatures = []
        if (!props.redux.enableFeaturePayroll) subFeatures.push('Overtime Calculation')
        if (!props.redux.enableFeatureSpecialLeave) subFeatures.push('Special Leave')
        if (!props.redux.enableFeatureReimbursement) subFeatures.push('Claim Type')
        if (!props.redux.enableFeaturePintuKerjoo) subFeatures.push('Pintu Kerjoo Attendance Approval')
        if (!props.redux.enableWhitelistDevice) subFeatures.push('Device Approval')
        if (!props.redux.enableFeatureMultilevelApproval) subFeatures.push('Approval Rules')
        const filteredSubMenu = children.slice().filter(item => !subFeatures.includes(item.submenucontent))

        item.Items[0].children = [...filteredSubMenu]
        return item
      }
      return item
    })

    return filteredMenuAndSubMenu
  }

  const [DATAMENU] = useState(props.redux.profile.supervisor_groups ? handleFeatures(cookies.get('menu-version') === '2' ? NEWMENUITEMSSUPERVISOR : MENUITEMSSUPERVISOR) : handleFeatures(cookies.get('menu-version') === '2' ? NEWMENUITEMS : MENUITEMS))
  const [mainmenu, setMainMenu] = useState(props.redux.profile.supervisor_groups ? handleFeatures(cookies.get('menu-version') === '2' ? NEWMENUITEMSSUPERVISOR : MENUITEMSSUPERVISOR) : handleFeatures(cookies.get('menu-version') === '2' ? NEWMENUITEMS : MENUITEMS))
  const [margin, setMargin] = useState(0)
  const [width, setWidth] = useState(0)
  const [lang] = useState(cookies.get('lang'))
  const [control, setControl] = useState({ onLoadSwitchLang: false })
  const pathUrls = useLocation()
  // const [sidebartoogle, setSidebartoogle] = useState(true)
  const wrapper = configDB.data.settings.sidebar.type
  const sidebartoogle = true
  useEffect(() => {
    // cookies.set('menu-version', '1')
    document.querySelector('.left-arrow').classList.add('d-none')

    window.addEventListener('resize', handleResize)
    handleResize()

    const currentUrl = window.location.pathname
    mainmenu.map(items => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl) { setNavActive(Items) }
        if (!Items.children) return false
        Items.children.filter(subItems => {
          if (subItems.path === currentUrl) { setNavActive(subItems) }
          if (!subItems.children) return false
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems)
              return true
            } else {
              return false
            }
          })
          return subItems
        })
        return Items
      })
      return items
    })

    return () => {
      window.removeEventListener('resize', handleResize)
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window.innerWidth > 991) {
      document.querySelector('.page-header').className = 'page-header'
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper'
      // document.querySelector('.mega-menu-container').classList.remove('d-block')
    }
  }, [width])

  const handleResize = () => {
    setWidth(window.innerWidth - 500)
  }

  useEffect(() => {
    window.addEventListener('popstate', handleChangeUrl())
    return () => {
      window.removeEventListener('popstate', handleChangeUrl())
    }
    // eslint-disable-next-line
  }, [pathUrls])

  const handleChangeUrl = () => {
    DATAMENU.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items.path !== pathUrls.pathname) { Items.active = false }
        if (Items.path === pathUrls.pathname) { Items.active = true }
        if (Items.children) {
          Items.children.map(submenuItems => {
            if (submenuItems.path !== pathUrls.pathname) {
              submenuItems.active = false
            }
            if (submenuItems.path === pathUrls.pathname) {
              submenuItems.active = true
              Items.active = true
            }
            if (submenuItems.childs) {
              submenuItems.childs.forEach(childPath => {
                if (matchPath(pathUrls.pathname, { path: childPath })) {
                  submenuItems.active = true
                  Items.active = true
                }
              })
            }
            return submenuItems
          })
        }
        return Items
      })
      return menuItems
    })
    setMainMenu({ mainmenu: DATAMENU })
  }

  const setNavActive = (item) => {
    DATAMENU.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items !== item) { Items.active = false }
        if (Items.children && Items.children.includes(item)) { Items.active = true }
        if (Items.children) {
          Items.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true
              submenuItems.active = true
              return true
            } else {
              return false
            }
          })
        }
        return Items
      })
      return menuItems
    })
    item.active = true
    setMainMenu({ mainmenu: DATAMENU })
  }

  const setNavActive2 = (item) => {
    DATAMENU.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items !== item) { Items.active = false }
        if (Items.children && Items.children.includes(item)) { Items.active = true }
        if (Items.children) {
          Items.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true
              submenuItems.active = true
              return true
            } else {
              return false
            }
          })
        }
        return Items
      })
      return menuItems
    })
    item.active = !item.active
    setMainMenu({ mainmenu: DATAMENU })
  }

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className = 'page-header close_icon'
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon '
      // document.querySelector('.mega-menu-container').classList.remove('d-block')
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header '
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper '
      }
    }

    if (!item.active) {
      DATAMENU.map(a => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) { Items.active = false }
          if (!Items.children) return false
          Items.children.forEach(b => {
            if (Items.children.includes(item)) {
              b.active = false
            }
            if (!b.children) return false
            b.children.forEach(c => {
              if (b.children.includes(item)) {
                c.active = false
              }
            })
          })
          return Items
        })
        return a
      })
    }
    item.active = true
    setMainMenu({ mainmenu: DATAMENU })
  }

  const handleMenuLink = (menuItem) => {
    setNavActive(menuItem)
    toggletNavActive(menuItem)
  }

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570)
      } else {
        setMargin(-3464)
      }
      document.querySelector('.right-arrow').classList.add('d-none')
      document.querySelector('.left-arrow').classList.remove('d-none')
    } else {
      setMargin(margin => margin += (-width))
      document.querySelector('.left-arrow').classList.remove('d-none')
    }
  }

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0)
      document.querySelector('.left-arrow').classList.add('d-none')
      document.querySelector('.right-arrow').classList.remove('d-none')
    } else {
      setMargin(margin => margin += width)
      document.querySelector('.right-arrow').classList.remove('d-none')
    }
  }

  // const openCloseSidebar = (toggle) => {
  //   if (toggle) {
  //     setSidebartoogle(!toggle)
  //     document.querySelector('.page-header').className = 'page-header close_icon'
  //     document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon '
  //   } else {
  //     setSidebartoogle(!toggle)
  //     document.querySelector('.page-header').className = 'page-header'
  //     document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper '
  //   }
  // }

  const responsiveSidebar = () => {
    document.querySelector('.page-header').className = 'page-header close_icon'
    document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon'
  }

  const switchVersion = () => {
    if (cookies.get('menu-version') === '2') {
      cookies.set('menu-version', '1')
      window.location.reload()
    } else {
      cookies.set('menu-version', '2')
      window.location.reload()
    }
  }

  const postLanguageUserService = (lang) => {
    setControl({ ...control, onLoadSwitchLang: true })
    axiosCompany().post('language', { lang: lang }).then(ress => {
      lang === 'en' ? LangEn() : LangId()
    })
  }

  return (
    <>
      <div className='sidebar-wrapper'>
        <div className='logo-wrapper d-flex d-md-block justify-content-between'>
          <Link to='/dashboard' onClick={() => setNavActive(DATAMENU[0].Items[0])}>
            <img className='for-light' src={LogoDark} style={{ maxHeight: 27 }} alt='Logo Kerjo' />
            <img className='for-dark' src={LogoLight} style={{ maxHeight: 27 }} alt='Logo Kerjo' />
          </Link>
          <span className='back-btn' onClick={() => responsiveSidebar()}><FontAwesomeIcon icon={faAngleLeft} /></span>

        </div>
        <div className='logo-icon-wrapper'>
          <Link to={`${process.env.PUBLIC_URL}/dashboard`}><img className='img-fluid' style={{ maxHeight: 27 }} src={LogoIcon} alt='' /></Link>
        </div>
        <nav className='sidebar-main'>
          <div className='left-arrow' onClick={scrollToLeft}><ArrowLeft /></div>
          <div id='sidebar-menu' style={wrapper === 'horizontal-wrapper' ? { marginLeft: margin + 'px' } : { margin: '0px' }}>
            <ul className='sidebar-links custom-scrollbar'>
              <li className='back-btn'>
                <div className='mobile-back text-right'><span>Back</span><FontAwesomeIcon icon={faAngleRight} className='pl-2' aria-hidden='true' /></div>
              </li>
              {
                DATAMENU.map((Item, i) => {
                  return (
                    <>
                      {
                        cookies.get('menu-version') === '2' && i === 0 &&
                        <li>
                          <div className={`sidebar-category mb-2 ${props.redux.darkModeOn ? 'text-light' : ''}`}>{LangAnalytics}</div>
                        </li>
                      }
                      {
                        Item.category === 'approval' && DATAMENU[i - 1].category !== 'approval' &&
                        <>
                          <hr style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }} />
                          <li>
                            <div className={`sidebar-category my-2 ${props.redux.darkModeOn ? 'text-light' : ''}`}>{LangApproval}</div>
                          </li>
                        </>
                      }
                      {
                        Item.category === 'manageEmployee' && DATAMENU[i - 1].category !== 'manageEmployee' &&
                        <>
                          <hr style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }} />
                          <li>
                            <div className={`sidebar-category my-2 ${props.redux.darkModeOn ? 'text-light' : ''}`}>{ManageEmployees}</div>
                          </li>
                        </>
                      }
                      {
                        Item.category === 'adjustment' && DATAMENU[i - 1].category !== 'adjustment' &&
                        <>
                          <hr style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }} />
                          <li>
                            <div className={`sidebar-category my-2 ${props.redux.darkModeOn ? 'text-light' : ''}`}>{LangAdjustment}</div>
                          </li>
                        </>
                      }
                      {
                        Item.category === 'system' && DATAMENU[i - 1].category !== 'system' &&
                        <>
                          <hr style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }} />
                          <li>
                            <div className={`sidebar-category my-2 ${props.redux.darkModeOn ? 'text-light' : ''}`}>{LangSystem}</div>
                          </li>
                        </>
                      }
                      <Fragment key={i}>
                        {Item.Items.map((menuItem, i) =>
                          <li className='sidebar-list' key={i}>
                            {(menuItem.type === 'sub')
                              ? (
                                <a
                                  href={menuItem.path} style={{ cursor: 'pointer' }} onClick={() => setNavActive2(menuItem)}
                                  className={`sidebar-link sidebar-title d-flex flex-nowrap align-items-center  ${menuItem.active ? 'active' : ''} ${Item.menucontent === 'Settings Employee' ? 'highlight-menu-employee' : ''} ${Item.menucontent === 'Work Time' ? 'highlight-menu-work-pattern' : ''} ${Item.menucontent === 'Settings' ? 'highlight-menu-settings' : ''}`}
                                >
                                  {
                                    cookies.get('menu-version') === '2' ?
                                      [LangAttendance, LangReview, Employe, LangTimesheet].includes(menuItem.title) ?
                                        <span className='d-flex align-items-center' style={{ width: '18px', height: '24px', marginRight: '10px' }}>{menuItem.icon}</span> :
                                        <menuItem.icon />
                                        :
                                        [LangClaim, LangTimesheet].includes(menuItem.title) ? 
                                        <span className='d-flex align-items-center' style={{ width: '18px', height: '24px', marginRight: '10px' }}>{menuItem.icon}</span> :
                                        <menuItem.icon />
                                  }
                                  <span>{props.t(menuItem.title)}</span>
                                  {menuItem.badge ? (<span className='d-flex flex-nowrap ml-2' style={{ width: 'fit-content' }}>{menuItem.badge}</span>) : ''}
                                  <div className='according-menu'>
                                    {menuItem.active
                                      ? <ChevronDown />
                                      : <ChevronRight />}
                                  </div>
                                </a>
                              )
                              : ''}

                            {(menuItem.type === 'link')
                              ? (
                                <Link
                                  to={menuItem.path} replace className={`sidebar-link sidebar-title link-nav d-flex flex-nowrap align-items-center ${menuItem.active
                                    ? 'active'
                                    : ''}`} href='#javascript' onClick={() => handleMenuLink(menuItem)}
                                >
                                  {
                                    cookies.get('menu-version') === '2' ?
                                      [LangClaim, LangUnpaidLeave, LangUserAdmin, LangSubscription].includes(menuItem.title) ?
                                        <span className='d-flex align-items-center' style={{ width: '18px', height: '24px', marginRight: '10px' }}>{menuItem.icon}</span>
                                        :
                                        <span style={{ width: '18px', height: '24px', marginRight: '10px' }}><menuItem.icon /></span>
                                      :
                                      <span style={{ width: '18px', height: '24px', marginRight: '10px' }}><menuItem.icon /></span>
                                  }
                                  <span className='text-nowrap'>{props.t(menuItem.title)}</span>
                                  {menuItem.badge ? (<span className='d-flex flex-nowrap ml-2' style={{ width: 'fit-content' }}>{menuItem.badge}</span>) : null}
                                </Link>
                              )
                              : ''}

                            {menuItem.children

                              ? (
                                <ul
                                  className='sidebar-submenu'
                                  style={menuItem.active ? sidebartoogle ? { opacity: 1, transition: 'opacity 500ms ease-in' } : { display: 'block' } : { display: 'none' }}
                                >

                                  {menuItem.children.map((childrenItem, index) => {
                                    return (
                                      <li key={index}>

                                        {(childrenItem.type === 'sub')
                                          ? (
                                            <a className={`${childrenItem.active ? '' : ''}`} href='#javascript' onClick={() => toggletNavActive(childrenItem)}>{props.t(childrenItem.title)}
                                              <span className='sub-arrow'>
                                                <FontAwesomeIcon icon={faChevronDown} />
                                              </span>
                                              <div className='according-menu'>
                                                {childrenItem.active
                                                  ? <FontAwesomeIcon icon={faAngleDown} />
                                                  : <FontAwesomeIcon icon={faAngleRight} />}
                                              </div>
                                            </a>
                                          )
                                          : ''}

                                        {(childrenItem.type === 'link')
                                          ? (
                                            <Link
                                              to={childrenItem.path} replace onClick={() => toggletNavActive(childrenItem)}
                                              className={`${childrenItem.active ? 'active' : ''} d-flex align-items-center ${childrenItem.title === ManageDivision ? 'highlight-submenu-grup' : ''} ${childrenItem.title === WorkDay ? 'highlight-submenu-work-day' : ''} ${childrenItem.title === ShiftDay ? 'highlight-submenu-work-shift' : ''} ${childrenItem.title === DataEmployees ? 'highlight-submenu-employee' : ''} ${childrenItem.title === WorkTime ? 'highlight-submenu-wdt' : ''} ${childrenItem.title === ShiftWorkTime ? 'highlight-submenu-wst' : ''} ${childrenItem.title === AttendanceSpot ? 'highlight-submenu-spot-attdc' : ''} ${childrenItem.title === CompanySetting ? 'highlight-submenu-company' : ''}`}
                                            >
                                              <span className='text-nowrap' style={{ color: 'inherit' }}>{props.t(childrenItem.title)}</span>
                                              <span className='d-flex flex-nowrap align-items-center ml-2' style={{ width: 'fit-content' }}>{childrenItem.badge ?? ''}</span>
                                            </Link>
                                          )
                                          : ''}

                                        {(childrenItem.type === 'exteral_link')
                                          ? <a href={childrenItem.path}>{props.t(childrenItem.title)}</a>
                                          : ''}

                                        {childrenItem.children
                                          ? (
                                            <ul
                                              className='nav-sub-childmenu submenu-content'
                                              style={childrenItem.active ? { display: 'block' } : { display: 'none' }}
                                            >
                                              {childrenItem.children.map((childrenSubItem, key) =>
                                                <li key={key}>
                                                  {(childrenSubItem.type === 'link')
                                                    ? <Link to={childrenSubItem.path} replace className={`${childrenSubItem.active ? 'active' : ''}`} onClick={() => toggletNavActive(childrenSubItem)}>{props.t(childrenSubItem.title)}</Link>
                                                    : ''}
                                                </li>
                                              )}
                                            </ul>
                                          )
                                          : ''}

                                      </li>
                                    )
                                  })}
                                </ul>
                              )
                              : ''}

                            {(menuItem.type === 'external-link')
                              ? (
                                <a
                                  href={menuItem.path} target='_blank' rel='noreferrer' className='sidebar-link sidebar-title link-nav d-flex flex-nowrap align-items-center'
                                >
                                  <span style={{ width: '18px', height: '30px', marginRight: '10px' }}>{menuItem.icon}</span>
                                  <span className='text-nowrap'>{props.t(menuItem.title)}</span>
                                  {menuItem.badge ? (<span className='d-flex flex-nowrap ml-2' style={{ width: 'fit-content' }}>{menuItem.badge}</span>) : null}
                                </a>
                              )
                              : ''}
                          </li>)}
                      </Fragment>
                    </>
                  )
                })
              }
              <hr className='d-block d-md-none' style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }} />
              <li className='d-block d-md-none'>
                <div className={`sidebar-category mb-2 ${props.redux.darkModeOn ? 'text-light' : ''}`}>{LangOther}</div>
              </li>
              <li className='d-block d-md-none mt-3'>
                <div className='border rounded mx-2 py-2 px-3 mt-2'>
                  <div className='d-flex align-items-center justify-content-between' type='button' data-toggle='collapse' data-target='#collapseSwitchDarkMode' aria-expanded='false' aria-controls='collapseSwitchDarkMode'>
                    <div className='d-flex align-items-center '>
                      {
                        props.redux.mode === 'light' &&
                        <svg style={{ width: 17, fill: props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                          <path d="M30,13.21A3.93,3.93,0,1,1,36.8,9.27L41.86,18A3.94,3.94,0,1,1,35.05,22L30,13.21Zm31.45,13A35.23,35.23,0,1,1,36.52,36.52,35.13,35.13,0,0,1,61.44,26.2ZM58.31,4A3.95,3.95,0,1,1,66.2,4V14.06a3.95,3.95,0,1,1-7.89,0V4ZM87.49,10.1A3.93,3.93,0,1,1,94.3,14l-5.06,8.76a3.93,3.93,0,1,1-6.81-3.92l5.06-8.75ZM109.67,30a3.93,3.93,0,1,1,3.94,6.81l-8.75,5.06a3.94,3.94,0,1,1-4-6.81L109.67,30Zm9.26,28.32a3.95,3.95,0,1,1,0,7.89H108.82a3.95,3.95,0,1,1,0-7.89Zm-6.15,29.18a3.93,3.93,0,1,1-3.91,6.81l-8.76-5.06A3.93,3.93,0,1,1,104,82.43l8.75,5.06ZM92.89,109.67a3.93,3.93,0,1,1-6.81,3.94L81,104.86a3.94,3.94,0,0,1,6.81-4l5.06,8.76Zm-28.32,9.26a3.95,3.95,0,1,1-7.89,0V108.82a3.95,3.95,0,1,1,7.89,0v10.11Zm-29.18-6.15a3.93,3.93,0,0,1-6.81-3.91l5.06-8.76A3.93,3.93,0,1,1,40.45,104l-5.06,8.75ZM13.21,92.89a3.93,3.93,0,1,1-3.94-6.81L18,81A3.94,3.94,0,1,1,22,87.83l-8.76,5.06ZM4,64.57a3.95,3.95,0,1,1,0-7.89H14.06a3.95,3.95,0,1,1,0,7.89ZM10.1,35.39A3.93,3.93,0,1,1,14,28.58l8.76,5.06a3.93,3.93,0,1,1-3.92,6.81L10.1,35.39Z" />
                        </svg>
                      }
                      {
                        props.redux.mode === 'dark-only' &&
                        <svg style={{ fillRule: 'evenodd', width: 17, fill: props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.56 122.88">
                          <path d="M121.85,87.3A64.31,64.31,0,1,1,36.88.4c2.94-1.37,5.92.91,4.47,4.47a56.29,56.29,0,0,0,75.75,77.4l.49-.27a3.41,3.41,0,0,1,4.61,4.61l-.35.69ZM92.46,74.67H92A16.11,16.11,0,0,0,76.2,58.93v-.52a15.08,15.08,0,0,0,11-4.72,15.19,15.19,0,0,0,4.72-11h.51a15.12,15.12,0,0,0,4.72,11,15.12,15.12,0,0,0,11,4.72v.51A16.13,16.13,0,0,0,92.46,74.67Zm10.09-46.59h-.27a7.94,7.94,0,0,0-2.49-5.81A7.94,7.94,0,0,0,94,19.78v-.27A7.94,7.94,0,0,0,99.79,17a8,8,0,0,0,2.49-5.8h.27A8,8,0,0,0,105,17a8,8,0,0,0,5.81,2.49v.27A8,8,0,0,0,105,22.27a7.94,7.94,0,0,0-2.49,5.81Zm-41.5,8h-.41a12.06,12.06,0,0,0-3.78-8.82A12.06,12.06,0,0,0,48,23.5v-.41a12.07,12.07,0,0,0,8.82-3.78,12.09,12.09,0,0,0,3.78-8.82h.41a12.08,12.08,0,0,0,3.77,8.82,12.09,12.09,0,0,0,8.83,3.78v.41a12.09,12.09,0,0,0-8.83,3.78,12.08,12.08,0,0,0-3.77,8.82Z" />
                        </svg>
                      }
                      {
                        props.redux.mode === 'auto' &&
                        <svg style={{ width: 17, fill: props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                          <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 508.47"><path fill-rule="nonzero" d="M254.23 508.47c-3.94 0-7.87-.1-11.77-.28h-1.54v-.07c-64.9-3.34-123.37-31.04-166.45-74.12C28.46 387.99 0 324.42 0 254.23c0-70.19 28.46-133.75 74.47-179.76C117.55 31.39 176.03 3.69 240.92.34V.27h1.54c3.9-.18 7.83-.27 11.77-.27l3.46.02.08-.02c70.19 0 133.75 28.46 179.76 74.47 46 46.01 74.47 109.57 74.47 179.76S483.53 387.99 437.53 434c-46.01 46.01-109.57 74.47-179.76 74.47l-.08-.03-3.46.03zm-13.31-30.56V30.56C184.33 33.87 133.4 58.17 95.79 95.79c-40.55 40.54-65.62 96.56-65.62 158.44 0 61.89 25.07 117.91 65.62 158.45 37.61 37.61 88.54 61.91 145.13 65.23z" /></svg>
                        </svg>
                      }
                      <span className='ml-2 text-nowrap txt-dark'>{props.redux.mode === 'light' ? LangLightMode : props.redux.mode === 'dark-only' ? LangDarkMode : LangAutoMode}</span>
                    </div>
                    <i className='middle fa fa-angle-down ml-2 txt-dark' />
                  </div>
                  <div className='collapse' id='collapseSwitchDarkMode'>
                    <hr className='my-2' style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }}/>
                    <ul className='ml-4'>
                      <li onClick={() => handleDarkMode('light', props)}>
                        <p className={`mb-0 ${props.redux.mode === 'light' ? 'txt-primary' : 'txt-dark'} d-flex`} style={{ fontSize: 14, gap: 12, cursor: 'pointer' }}>
                          <svg style={{ width: 17, fill: props.redux.mode === 'light' ? '#7366ff' : props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                            <path d="M30,13.21A3.93,3.93,0,1,1,36.8,9.27L41.86,18A3.94,3.94,0,1,1,35.05,22L30,13.21Zm31.45,13A35.23,35.23,0,1,1,36.52,36.52,35.13,35.13,0,0,1,61.44,26.2ZM58.31,4A3.95,3.95,0,1,1,66.2,4V14.06a3.95,3.95,0,1,1-7.89,0V4ZM87.49,10.1A3.93,3.93,0,1,1,94.3,14l-5.06,8.76a3.93,3.93,0,1,1-6.81-3.92l5.06-8.75ZM109.67,30a3.93,3.93,0,1,1,3.94,6.81l-8.75,5.06a3.94,3.94,0,1,1-4-6.81L109.67,30Zm9.26,28.32a3.95,3.95,0,1,1,0,7.89H108.82a3.95,3.95,0,1,1,0-7.89Zm-6.15,29.18a3.93,3.93,0,1,1-3.91,6.81l-8.76-5.06A3.93,3.93,0,1,1,104,82.43l8.75,5.06ZM92.89,109.67a3.93,3.93,0,1,1-6.81,3.94L81,104.86a3.94,3.94,0,0,1,6.81-4l5.06,8.76Zm-28.32,9.26a3.95,3.95,0,1,1-7.89,0V108.82a3.95,3.95,0,1,1,7.89,0v10.11Zm-29.18-6.15a3.93,3.93,0,0,1-6.81-3.91l5.06-8.76A3.93,3.93,0,1,1,40.45,104l-5.06,8.75ZM13.21,92.89a3.93,3.93,0,1,1-3.94-6.81L18,81A3.94,3.94,0,1,1,22,87.83l-8.76,5.06ZM4,64.57a3.95,3.95,0,1,1,0-7.89H14.06a3.95,3.95,0,1,1,0,7.89ZM10.1,35.39A3.93,3.93,0,1,1,14,28.58l8.76,5.06a3.93,3.93,0,1,1-3.92,6.81L10.1,35.39Z" />
                          </svg>
                          {LangLightMode}
                        </p>
                      </li>
                      <hr className='my-2' style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }}/>
                      <li onClick={() => handleDarkMode('dark-only', props)}>
                        <p className={`mb-0 ${props.redux.mode === 'dark-only' ? 'txt-primary' : 'txt-dark'} d-flex`} style={{ fontSize: 14, gap: 12, cursor: 'pointer' }}>
                          <svg style={{ width: 17, fill: props.redux.mode === 'dark-only' ? '#7366ff' : props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" viewBox="0 0 122.56 122.88">
                            <path d="M121.85,87.3A64.31,64.31,0,1,1,36.88.4c2.94-1.37,5.92.91,4.47,4.47a56.29,56.29,0,0,0,75.75,77.4l.49-.27a3.41,3.41,0,0,1,4.61,4.61l-.35.69ZM92.46,74.67H92A16.11,16.11,0,0,0,76.2,58.93v-.52a15.08,15.08,0,0,0,11-4.72,15.19,15.19,0,0,0,4.72-11h.51a15.12,15.12,0,0,0,4.72,11,15.12,15.12,0,0,0,11,4.72v.51A16.13,16.13,0,0,0,92.46,74.67Zm10.09-46.59h-.27a7.94,7.94,0,0,0-2.49-5.81A7.94,7.94,0,0,0,94,19.78v-.27A7.94,7.94,0,0,0,99.79,17a8,8,0,0,0,2.49-5.8h.27A8,8,0,0,0,105,17a8,8,0,0,0,5.81,2.49v.27A8,8,0,0,0,105,22.27a7.94,7.94,0,0,0-2.49,5.81Zm-41.5,8h-.41a12.06,12.06,0,0,0-3.78-8.82A12.06,12.06,0,0,0,48,23.5v-.41a12.07,12.07,0,0,0,8.82-3.78,12.09,12.09,0,0,0,3.78-8.82h.41a12.08,12.08,0,0,0,3.77,8.82,12.09,12.09,0,0,0,8.83,3.78v.41a12.09,12.09,0,0,0-8.83,3.78,12.08,12.08,0,0,0-3.77,8.82Z" />
                          </svg>
                          {LangDarkMode}
                        </p>
                      </li>
                      <hr className='my-2' style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }}/>
                      <li onClick={() => handleDarkMode('auto', props)}>
                        <p className={`mb-0 ${props.redux.mode === 'auto' ? 'txt-primary' : 'txt-dark'} d-flex`} style={{ fontSize: 14, gap: 12, cursor: 'pointer' }}>
                          <svg style={{ width: 17, fill: props.redux.mode === 'auto' ? '#7366ff' : props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 508.47"><path fill-rule="nonzero" d="M254.23 508.47c-3.94 0-7.87-.1-11.77-.28h-1.54v-.07c-64.9-3.34-123.37-31.04-166.45-74.12C28.46 387.99 0 324.42 0 254.23c0-70.19 28.46-133.75 74.47-179.76C117.55 31.39 176.03 3.69 240.92.34V.27h1.54c3.9-.18 7.83-.27 11.77-.27l3.46.02.08-.02c70.19 0 133.75 28.46 179.76 74.47 46 46.01 74.47 109.57 74.47 179.76S483.53 387.99 437.53 434c-46.01 46.01-109.57 74.47-179.76 74.47l-.08-.03-3.46.03zm-13.31-30.56V30.56C184.33 33.87 133.4 58.17 95.79 95.79c-40.55 40.54-65.62 96.56-65.62 158.44 0 61.89 25.07 117.91 65.62 158.45 37.61 37.61 88.54 61.91 145.13 65.23z" /></svg>
                          {LangAutoMode}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div id='switch-mode-sidebar' className='border rounded mx-2 py-2 px-3 mt-2 d-flex align-items-center justify-content-between' style={{ cursor: 'pointer' }} onClick={switchMode}>
                  <span className='text-nowrap txt-dark' style={{ lineHeight: 1.7, cursor: 'pointer' }}>Dark Mode</span>
                  <div className='custom-control custom-switch'>
                    <input type='checkbox' role='button' className='custom-control-input' id='switch-dark-sidebar' checked={cookies.get('mode') === 'dark-only'} />
                    <label className='custom-control-label text-nowrap' htmlFor='switch-dark-sidebar' style={{ lineHeight: 1.7, cursor: 'pointer' }}></label>
                  </div>
                </div> */}
              </li>
              <li className='d-block d-md-none mt-3'>
                <div className='border rounded mx-2 py-2 px-3 mt-2'>
                  <div className='d-flex align-items-center justify-content-between' type='button' data-toggle='collapse' data-target='#collapseSwitchLangSidebar' aria-expanded='false' aria-controls='collapseSwitchLangSidebar'>
                    <div className='d-flex align-items-center '>
                      <svg style={{ width: 18, fill: props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 92.91"><path d="M20.15,83.63,31.63,73.4a2.89,2.89,0,0,1,1.91-.73h27.8a.92.92,0,0,0,.93-.93V65.9H68v5.84a6.71,6.71,0,0,1-6.68,6.68H34.62L19.3,92.07a2.87,2.87,0,0,1-4.9-2V78.42H6.69A6.71,6.71,0,0,1,0,71.74V28.59a6.76,6.76,0,0,1,6.69-6.68H43.35v5.75H6.69a1,1,0,0,0-.94.93V71.74a.91.91,0,0,0,.28.65,1,1,0,0,0,.66.28H17.27a2.88,2.88,0,0,1,2.88,2.88v8.08Zm.21-19.48L29.6,36.24h8.83l9.24,27.91H40.35L38.8,59.07H29.15l-1.51,5.08ZM30.79,53.24h6.37L34,41.81,30.79,53.24ZM76.63,13.35h8.7V11.11a.69.69,0,0,1,.69-.69h4.65a.68.68,0,0,1,.68.69v2.24h9.76a.68.68,0,0,1,.68.69V18.5a.68.68,0,0,1-.68.68H99.56a26.3,26.3,0,0,1-.91,3.88l0,.06a26.07,26.07,0,0,1-1.74,4.15,32.34,32.34,0,0,1-2.14,3.43c-.67,1-1.41,1.9-2.2,2.83a35.78,35.78,0,0,0,3.68,3.83,41.43,41.43,0,0,0,5.09,3.74.68.68,0,0,1,.21.94l-2.39,3.73a.69.69,0,0,1-1,.2,45.88,45.88,0,0,1-5.58-4.08l0,0a41.42,41.42,0,0,1-4-4.1C87.3,38.93,86.15,40,85,41l0,0c-1.36,1.12-2.79,2.2-4.47,3.36a.69.69,0,0,1-1-.17L77,40.53a.69.69,0,0,1,.17-1c1.66-1.14,3-2.19,4.36-3.28,1.16-1,2.28-2,3.49-3.16a44.82,44.82,0,0,1-2.77-4.45A28.84,28.84,0,0,1,80,22.9a.68.68,0,0,1,.47-.84l4.27-1.19a.68.68,0,0,1,.84.47A22.62,22.62,0,0,0,89,28.7L90.27,27a26.33,26.33,0,0,0,1.51-2.47l0,0A19.43,19.43,0,0,0,93,21.62a24,24,0,0,0,.66-2.44h-17a.69.69,0,0,1-.69-.68V14a.69.69,0,0,1,.69-.69Zm27,56.82L88.26,56.51H61.54a6.73,6.73,0,0,1-6.69-6.68V6.69a6.71,6.71,0,0,1,2-4.72l.2-.18A6.67,6.67,0,0,1,61.54,0h54.65a6.69,6.69,0,0,1,4.71,2l.19.2a6.69,6.69,0,0,1,1.79,4.51V49.83a6.73,6.73,0,0,1-6.69,6.68h-7.7V68.13a2.88,2.88,0,0,1-4.91,2ZM91.26,51.49l11.47,10.23V53.64a2.88,2.88,0,0,1,2.88-2.88h10.58a.92.92,0,0,0,.65-.28.91.91,0,0,0,.29-.65V6.69a1,1,0,0,0-.22-.58L116.84,6a1,1,0,0,0-.65-.29H61.54A.94.94,0,0,0,61,6L60.89,6a.92.92,0,0,0-.28.65V49.83a.92.92,0,0,0,.93.93H89.35a2.86,2.86,0,0,1,1.91.73Z" /></svg>
                      <span className='ml-2 text-nowrap txt-dark'>{cookies.get('lang') === 'ID' ? 'Bahasa Indonesia' : 'English'} <span className='ml-1'>{control.onLoadSwitchLang ? SpinnerButton : ''}</span></span>
                    </div>
                    <i className='middle fa fa-angle-down ml-2 txt-dark' />
                  </div>
                  <div className='collapse' id='collapseSwitchLangSidebar'>
                    <hr className='my-2' style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }}/>
                    <ul className='ml-4'>
                      <li onClick={() => { lang === 'EN' && postLanguageUserService('id') }}>
                        <p className={`mb-0 ${lang === 'ID' ? 'txt-primary' : 'txt-dark'}`} style={{ fontSize: 14, cursor: 'pointer' }}>- Bahasa Indonesia</p>
                      </li>
                      <hr className='my-2' style={{ borderColor: props.redux.darkModeOn ? '#374558' : '#0000001a' }}/>
                      <li onClick={() => { lang === 'ID' && postLanguageUserService('en') }}>
                        <p className={`mb-0 ${lang === 'EN' ? 'txt-primary' : 'txt-dark'}`} style={{ fontSize: 14, cursor: 'pointer' }}>- English</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li className='d-block d-md-none mt-3'>
                <div className='border rounded mx-2 py-2 px-3 mt-2'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <svg style={{ width: 18, fill: props.redux.darkModeOn ? '#fff' : '' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80"><path d="M56.51,36.91A25.24,25.24,0,0,0,57,32a24.9,24.9,0,0,0-6.38-16.66.47.47,0,0,1-.06-.09h0a25,25,0,0,0-37.08,0h0a.47.47,0,0,1-.06.09,24.94,24.94,0,0,0,0,33.33l0,.07,0,0A25,25,0,0,0,32,57a25.24,25.24,0,0,0,4.91-.49,14,14,0,1,0,19.6-19.6ZM48,34a14.08,14.08,0,0,0-2.06.17c0-.39,0-.78,0-1.17h9a24.38,24.38,0,0,1-.27,2.71A13.9,13.9,0,0,0,48,34ZM14.16,46.49A22.77,22.77,0,0,1,9,33h9a43.15,43.15,0,0,0,1.24,9.5A25.38,25.38,0,0,0,14.16,46.49Zm0-29a25.38,25.38,0,0,0,5.09,4A43.15,43.15,0,0,0,18,31H9A22.77,22.77,0,0,1,14.16,17.51Zm35.68,0A22.77,22.77,0,0,1,55,31H46a43.15,43.15,0,0,0-1.24-9.5A25.38,25.38,0,0,0,49.84,17.51ZM44,31H33V25a25.09,25.09,0,0,0,9.92-2.49A41.17,41.17,0,0,1,44,31ZM33,23V9.09c4,.63,7.47,5.07,9.39,11.42A22.93,22.93,0,0,1,33,23ZM31,9.09V23a22.93,22.93,0,0,1-9.39-2.46C23.53,14.16,27,9.72,31,9.09ZM31,25v6H20a41.17,41.17,0,0,1,1.07-8.52A25.09,25.09,0,0,0,31,25ZM20,33H31v6a25.09,25.09,0,0,0-9.92,2.49A41.17,41.17,0,0,1,20,33Zm11,8V54.91c-4-.63-7.47-5.07-9.39-11.42A22.93,22.93,0,0,1,31,41Zm2,13.89V41a24.38,24.38,0,0,1,2.71.27A13.93,13.93,0,0,0,35.38,54,7.21,7.21,0,0,1,33,54.92Zm3.91-15.43A25.6,25.6,0,0,0,33,39V33H44c0,.54,0,1.08-.07,1.61A14,14,0,0,0,36.91,39.49ZM48.51,16a23.33,23.33,0,0,1-4.33,3.49A22.7,22.7,0,0,0,38.77,10,23,23,0,0,1,48.51,16ZM25.23,10a22.7,22.7,0,0,0-5.41,9.48A23.33,23.33,0,0,1,15.49,16,23,23,0,0,1,25.23,10ZM15.49,48a23.33,23.33,0,0,1,4.33-3.49A22.7,22.7,0,0,0,25.23,54,23,23,0,0,1,15.49,48ZM48,60A12,12,0,1,1,60,48,12,12,0,0,1,48,60Z" /><path d="M48,38a1,1,0,0,0-1,1v8.52l-4.62,3.7a1,1,0,0,0-.16,1.4,1,1,0,0,0,1.41.16l5-4A1,1,0,0,0,49,48V39A1,1,0,0,0,48,38Z" /></svg>
                      <span className='ml-2 text-nowrap txt-dark' style={{ lineHeight: 1 }}>{displayTimezone(props.redux.profile.attr.timezone)} <span className='ml-1'>{control.onLoadSwitchTimezone ? SpinnerButton : ''}</span></span>
                    </div>
                    {/* <i className='middle fa fa-angle-down ml-2 txt-dark' /> */}
                  </div>
                  {/* <div className='collapse' id='collapseSwitchTimezoneSidebar'>
                    <ul className='ml-4'>
                      <li onClick={() => { timezone !== 'wib' && editTimezone('wib') }}>
                        <p className={`mb-0 ${timezone === 'wib' ? 'txt-primary' : 'txt-dark'} mt-3`} style={{ fontSize: 14 }}>- WIB (UTC+7)</p>
                      </li>
                      <li onClick={() => { timezone !== 'wita' && editTimezone('wita') }}>
                        <p className={`mb-0 ${timezone === 'wita' ? 'txt-primary' : 'txt-dark'} mt-2`} style={{ fontSize: 14 }}>- WITA (UTC+8)</p>
                      </li>
                      <li onClick={() => { timezone !== 'wit' && editTimezone('wit') }}>
                        <p className={`mb-0 ${timezone === 'wit' ? 'txt-primary' : 'txt-dark'} mt-2`} style={{ fontSize: 14 }}>- WIT (UTC+9)</p>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </li>
              <li>
                <div className='my-3 d-flex justify-content-center'>
                  <button class="btn btn-pill btn-outline-primary btn-sm d-flex align-items-center" type="button" onClick={switchVersion}>
                    <span className='d-flex align-items-center mr-1' style={{ width: '18px', height: '24px' }}>
                      <FontAwesomeIcon icon={faRetweet} />
                    </span>
                    {cookies.get('menu-version') === '2' ? LangSwitchToOldVersion : LangSwitchToNewVersion}
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div className='right-arrow' onClick={scrollToRight}><ArrowRight /></div>
        </nav>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(translate(Sidebar))
