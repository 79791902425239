import { faInfoCircle, faSearch, faTrash, faPencilAlt, faHistory, faMapMarkerAlt, faFileDownload, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Action, AddClient, AdditionalField, Address, ChangeHistories, Delete, Edit, EditClient, LangAdd, LangAvailableFeatureInfo, LangClient, LangClientVisit, LangConfirm, LangDeletePermanentConfirm, LangImportExcel, LangImportExcelClientFormat, LangVisitHistory, Name, Search } from '../../constant/languange'
import { axiosCompany, capitalizeFirstLetter, lang, Link, mapStateToProps, PaginationDiv, SpinnerButton, SpinnerLoadFirst } from '../../helpers/Common'
import ModalConfirmComponent from '../common/ModalConfirmComponent'
import BreadcrumbsMenu from '../../layout/breadcrumb/breadcrumbMenu'
import ClientAddClientComponent from './ClientAddClientComponent'
import { ModalApp } from '../../helpers/Input'
import ClientEditClientComponent from './ClientEditClientComponent'
import ModalAdminActivityLogsComponent from '../admin/ModalAdminActivityLogsComponent'
import ClientImportComponent from './ClientImportComponent'
import ClientImportFormatComponent from './ClientImportFormatComponent'

function ClientComponent ({ redux }) {
  const [control, setControl] = useState({ showLoad: false, showModal: '' })
  const [filter, setFilter] = useState({
    page: 1,
    keyword: ''
  })
  const [dataResponse, setDataResponse] = useState({
    data: [],
    meta: {
      total: 0
    }
  })
  const [currentData, setCurrentData] = useState({})
  const [errors, setError] = useState({ fields: [], message: '' })

  useEffect(() => {
    getListService()
    // eslint-disable-next-line
  }, [])

  const getListService = () => {
    setControl(control => ({ ...control, showLoad: true }))

    axiosCompany().get('/client-visit-clients', { params: filter })
      .then(res => {
        setDataResponse({ ...res.data })
        setControl(control => ({ ...control, showLoad: false }))
      }).catch(e => {
        setControl(control => ({ ...control, showLoad: false }))
      })
  }

  const deleteDataService = () => {
    setControl(control => ({ ...control, showLoad: true }))

    axiosCompany().delete(`client-visit-clients/${currentData.id}`)
      .then(() => {
        setControl(control => ({ ...control, showLoad: false, showModal: '' }))
        getListService()
      }).catch(e => {
        setError(error => {
          error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
          error.message = e.response.data.message ? e.response.data.message : ''
          return { ...error }
        })
        setControl(control => ({ ...control, showLoad: false }))
      })
  }

  const handleSearch = () => {
    filter.page = 1
    getListService()
  }

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      filter.page = 1
      getListService()
    }
  }

  const handlePageChange = (page) => {
    filter.page = page
    getListService()
  }

  const handleModalAction = (payload, type = '') => {
    setCurrentData({ ...payload })
    setControl(control => ({ ...control, showModal: type }))
  }

  return (
    <div>
      <BreadcrumbsMenu title={LangClient}>{[LangClientVisit, LangClient]}</BreadcrumbsMenu>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col md-12'>
            <div className='card'>
              <div className='card-header'>
                {(redux.isTrial)
                  ? (
                    <div className='alert alert-info outline'>
                      <FontAwesomeIcon icon={faInfoCircle} /> {lang(LangAvailableFeatureInfo, { package: capitalizeFirstLetter(redux.features?.client_visit?.min_package) })}
                    </div>
                    )
                  : null}

                <div className='d-md-flex justify-content-between'>
                  <div className='mr-2 mb-2'>
                    <div className='input-group'>
                      <input type='search' className='form-control' placeholder={Search} value={filter.keyword} onKeyDown={handleKeydown} onChange={(e) => { setFilter({ ...filter, keyword: e.target.value }) }} style={{ maxWidth: 230 }} />
                      <div className='input-group-append' disabled={control.showLoad} id='button-addon2' onClick={handleSearch}>
                        <span className={`input-group-text ${redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}>
                          {control.showLoad ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button onClick={() => setControl(control => ({ ...control, showModal: 'add-client' }))} type='button' className='mb-2 mr-2 btn btn-primary'>{(control.showLoad) ? SpinnerButton : <FontAwesomeIcon icon={faPlus} />} {LangAdd}</button>
                    <button onClick={() => setControl(control => ({ ...control, showModal: 'import' }))} disabled={control.showLoad} type='button' className='mb-2 btn btn-primary'>{(control.showLoad) ? SpinnerButton : <FontAwesomeIcon icon={faFileDownload} />} {LangImportExcel}</button>
                  </div>
                </div>
              </div>
              {
                control.showLoad
                  ? SpinnerLoadFirst
                  : (
                    <div class='table-responsive'>
                      <table class='table table-striped table-bordered'>
                        <thead>
                          <tr>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 200 }}>{Name}</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 200 }}>{Address}</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 100 }}>{AdditionalField}</th>
                            <th scope='col' className='text-center align-middle' style={{ minWidth: 100 }}>{Action}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataResponse.data.map((item, i) => (
                            <tr key={i}>
                              <th scope='row'>{item.name}</th>
                              <td>{item.address}</td>
                              <td>{item.additional_fields_count} Field</td>
                              <td style={{ minWidth: 180 }}>
                                <a className={`${redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-sm mr-2 mb-2 d-inline-block`} data-tip={Edit} href='#' onClick={(e) => { e.preventDefault(); handleModalAction(item, 'edit-client') }}><FontAwesomeIcon icon={faPencilAlt} color={redux.darkModeOn ? 'white' : ''} /></a>
                                <a className={`${redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-sm mr-2 mb-2 d-inline-block`} style={{ cursor: 'pointer' }} data-tip={ChangeHistories} onClick={(e) => { e.preventDefault(); handleModalAction(item, 'activity-log') }}><FontAwesomeIcon icon={faHistory} color={redux.darkModeOn ? 'white' : ''} /></a>
                                <Link className={`${redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-sm mr-2 mb-2 d-inline-block`} data-tip={LangVisitHistory} to={`/client-visit?client_id=${item.id}`}><FontAwesomeIcon icon={faMapMarkerAlt} color={redux.darkModeOn ? 'white' : ''} /></Link>
                                <a className='btn-danger btn-sm mr-2 mb-2 d-inline-block' data-tip={Delete} href='#' onClick={(e) => { e.preventDefault(); handleModalAction(item, 'delete') }}><FontAwesomeIcon icon={faTrash} /></a>
                                <ReactTooltip place='bottom' />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    )
              }
              <div className='card-footer'>
                <PaginationDiv data={dataResponse} onChange={handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {(control.showModal === 'import') && (
        <ModalApp
          title={LangImportExcel}
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={
            <ClientImportComponent
              handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
              handleDownloadFormat={() => setControl(control => ({ ...control, showModal: 'import-format' }))}
              handleUpdate={getListService}
            />
            }
        />
      )}

      {(control.showModal === 'import-format') && (
        <ModalApp
          title={LangImportExcelClientFormat}
          handleClose={() => setControl(control => ({ ...control, showModal: 'import' }))}
          body={
            <ClientImportFormatComponent
              handleClose={() => setControl(control => ({ ...control, showModal: 'import' }))}
              groups={redux.groups}
            />
            }
        />
      )}

      {(control.showModal === 'activity-log') && (
        <ModalApp
          title={ChangeHistories}
          size='xl'
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={<ModalAdminActivityLogsComponent type='ClientVisitClient' id={currentData.id} />}
        />
      )}

      {(control.showModal === 'add-client') && (
        <ModalApp
          title={AddClient}
          size='xl'
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={
            <ClientAddClientComponent
              handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
              handleUpdate={() => getListService()}
            />
          }
        />
      )}

      {(control.showModal === 'edit-client') && (
        <ModalApp
          title={EditClient}
          size='xl'
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          body={
            <ClientEditClientComponent
              handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
              handleUpdate={() => getListService()}
              currentData={currentData}
            />
}
        />
      )}

      {(control.showModal === 'delete') && (
        <ModalApp
          size='md'
          title={LangConfirm}
          handleClose={() => { setControl({ ...control, showModal: '' }); setError({ fields: [], message: '' }) }}
          body={
            <ModalConfirmComponent
              caption={LangDeletePermanentConfirm}
              btnConfirmColor='danger'
              errorMessage={errors.message}
              loader={control.showLoad}
              onSubmit={() => deleteDataService()}
              onClose={() => { setControl({ ...control, showModal: '' }); setError({ fields: [], message: '' }) }}
            />
          }
        />
      )}
    </div>

  )
}

export default connect(mapStateToProps)(ClientComponent)
